@import "../../../theme/colors";

.survey-question {
  .multiSelectContainer {
    width: 304px;
    .searchWrapper {
      background: #F6F6F6;
      border: 1px solid #E9E9E9;
      border-radius: 4px;
      min-height: 55px;
      width: 307px;
      padding: 14px;
      input[type='text'] {
        border: none;
        margin: 0;
        padding: 0;
        display: inline;
        width: 82px;
        height: auto;
        &::placeholder {
          color: $primary-text-color;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 23px;
          display: flex;
          align-items: center;
          letter-spacing: 0.0120588em;
          opacity: 0.65;
        }
      }
      .chip {
        position: relative;
        font-family: 'NunitoSans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        background: #fff;
        letter-spacing: 0.0120588em;
        border-radius: 0;
        color: $primary-text-color;
        white-space: pre-wrap;
        padding: 8px;
        &::after {
          content: " ";
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 10px;
          height: 10px;
          background: url("../../../resources/images/close.svg") 10px center;
          background-size: contain;
          cursor: pointer;
        }
      }
      .closeIcon {
        margin-left: 12px;
        opacity: 0;
        z-index: 99;
      }
      .icon_cancel {
        width: 20px;
        height: 11px;
        right: 8px;
      }
    }
    .optionListContainer {
      z-index: 80;
      .optionContainer {
        .option {
          font-family: 'NunitoSans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;

          display: flex;
          align-items: center;
          letter-spacing: 0.0120588em;

          color: $primary-text-color;
          padding: 15px 21px;
          .checkbox {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #D5D5DA;
            box-sizing: border-box;
            border-radius: 3px;
            width: 24px;
            height: 24px;
            min-width: 24px;
            margin-right: 16px;
            cursor: pointer;
            appearance: auto;
          }
          &:hover {
            background: #D5D5DA;
          }
        }
        .highlightOption {
          background: none;
        }
      }
    }
  }
  .parent-checked-green .checkbox {
    accent-color: #397974;
  }
  .parent-checked-brown .checkbox {
    accent-color: #816F4B;
  }
  .parent-checked-red .checkbox {
    accent-color: #A02C5E;
  }
}

